.toaster {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.toast {
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.toast-success {
  background-color: green;
}

.toast-error {
  background-color: red;
}

.toast-info {
  background-color: blue;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}